import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
interface ReqInfo {
  url: string
  method: MethodType
  headers: Record<string, string>
  bodyArgs: APIBodyArgCollection
}

type APIQueryArgCollection = ApiTypes.ApiBodyArgCollection
type APIBodyArgCollection = ApiTypes.ApiBodyArgCollection

export class ApiProxy {

  static ACCESS_TOKEN = ''

  public async sendRequest(
    method: MethodType,
    pathPrefix: string,
    urlArgs: APIQueryArgCollection = {},
    bodyArgs: APIBodyArgCollection = {}
  ): Promise<any> {
    let path = ''
    if (path.indexOf('http') === 0) {
      path = commonUtils.underlizeKey(path)
    } else {
      if (envHelper.isProd) {
        path = `${envHelper.apiHost}${envHelper.apiBasePath}${commonUtils.underlizeKey(pathPrefix)}`
      } else {
        path = `${envHelper.apiBasePath}${commonUtils.underlizeKey(pathPrefix)}`
      }
    }
    // 清空值为undefined的参数
    urlArgs = this.filterUrlArgs(urlArgs)
    bodyArgs = this.filterUrlArgs(bodyArgs)
    const url = commonUtils.genApiUrl(path, commonUtils.underlize(urlArgs || {}))
    const headers = await this.getAuthHeaders()
    return this.handleSendRequest({
      url, headers, method,
      bodyArgs
    })
  }

  private handleSendRequest(reqInfo: ReqInfo): Promise<any> {
    const body: RequestInit = {
      method: reqInfo.method,
      headers: reqInfo.headers,
    }
    if (reqInfo.method === 'POST') {
      body.body = JSON.stringify(commonUtils.underlize(reqInfo.bodyArgs || {}))
    }
    const req = fetch(reqInfo.url, body)
    return req.then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      throw commonUtils.camelize(res)
    }).then((res) => {
      if (res.success) {
        return commonUtils.camelize(res)
      }
      throw commonUtils.camelize(res)
    })
  }

  private async getAuthHeaders(): Promise<{ [key: string]: string }> {
    return {
      'Authorization': `Bearer ${ApiProxy.ACCESS_TOKEN}`,
    }
  }

  private filterUrlArgs(urlArgs?: any): any {
    if (!urlArgs) {
      return {}
    }
    const args: any = {}
    for (const key in urlArgs) {
      if (urlArgs[key] !== undefined) {
        args[key] = urlArgs[key]
      }
    }
    return args
  }

}
