import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { ApiProxy } from '@/helpers/api/ApiProxy'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { makeObservable, observable } from 'mobx'
import { routerService } from '../routerService'

class UserService {
  @observable userData: ServerData.UserData | null = null

  constructor() {
    makeObservable(this)
  }

  public init() {
    this.initAccessToken()
  }

  public login(loginInfo: { username: string, password: string }) {
    api.user
      .login(loginInfo)
      .then(({ data: { accessToken } }) => {
        storageHelper.set({ accessToken })
        this.initAccessToken()
        uiController.message.success('登录成功')
        setTimeout(() => {
          routerService.navigateTo("ruleManagement")
        }, 500)
      })
      .catch((e) => {
        uiController.message.error(commonUtils.formatErrorMsg(e))
      })
  }

  public checkIsLogin(): boolean {
    const { accessToken } = storageHelper.get(['accessToken'])
    return !!accessToken
  }

  public async updateUserInfo() {
    try {
      const { data } = await api.user.getUser()
      this.userData = data
    } catch (error) {
      this.logout()
    }
  }

  public async logout() {
    storageHelper.set({ accessToken: null })
    routerService.navigateTo('login')
  }

  private initAccessToken() {
    const { accessToken } = storageHelper.get(['accessToken'])
    ApiProxy.ACCESS_TOKEN = accessToken!
    this.updateUserInfo()
  }

}

export const userService = new UserService()
