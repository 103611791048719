class EnvHelper {

  get extensionPrefix() {
    return 'ad_admin'
  }

  get isProd() {
    return process.env.REACT_APP_ENV === 'production'
  }

  get productName() {
    return '投放管理后台'
  }

  get host() {
    return process.env.REACT_APP_HOST
  }

  get apiHost() {
    return this.host
  }

  get apiBasePath() {
    return '/ad_admin_api'
  }

}

export const envHelper = new EnvHelper()
