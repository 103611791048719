import { ElementType, Suspense, lazy } from "react"
import { RouteObject, useRoutes } from "react-router-dom"
import AmeSpin from "./components/AmeSpin/AmeSpin"
import { PAGE_LINKS } from "./helpers/commonConsts"

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<AmeSpin fullscreen={true} />}>
      <Component {...props} />
    </Suspense>
  )

const LoginPage = Loadable(lazy(() => import("./pages/LoginPage/LoginPage")))
const RuleManagementPage = Loadable(
  lazy(() => import("./pages/RuleManagementPage/RuleManagementPage"))
)

const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.home,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.ruleManagement,
    element: <RuleManagementPage />,
  },
]

export const Router = () => {
  return useRoutes(routes)
}
