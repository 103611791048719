import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace group {

  /** 获取分组列表 */
  export function getGroupList(): Promise<ApiBaseResult & {
    data: {
      totalCount: number
      data: Array<ServerData.Group>
    }
  }> {
    return get('/landing_page/ab_rule_group/list')
  }

  /** 创建分组 */
  export function createGroup(data: { name: string, remark?: string }): Promise<ApiBaseResult> {
    return post('/landing_page/ab_rule_group/create', {}, data)
  }

  /** 更新分组 */
  export function updateGroup(groupId: number, data: { name: string, remark?: string }): Promise<ApiBaseResult> {
    return post('/landing_page/ab_rule_group/update', { groupId }, data)
  }

  /** 删除分组 */
  export function removeGroup(groupId: number): Promise<ApiBaseResult> {
    return post('/landing_page/ab_rule_group/delete', { groupId })
  }
}

