const STORAGE_KEY = 'ad_admin_cache_data'

class StorageHelper {

  public set(data: Partial<StorageData>, callback?: () => void): void {
    const cachedData = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
    let k: StorageKey
    for (k in data) {
      cachedData[k] = data[k]
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(cachedData))
    callback?.()
  }

  public get<K extends StorageKey>(keys: K[]): Pick<StorageData, K> {
    const cachedData = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
    const resultData: Pick<StorageData, K> = {} as unknown as Pick<StorageData, K>
    keys.forEach((k) => {
      resultData[k] = cachedData[k]
    })
    return resultData
  }

}

export const storageHelper = new StorageHelper()
