import { ConfigProvider, message } from "antd"
import locale from "antd/locale/zh_CN"
import dayjs from "dayjs"
import { useLayoutEffect } from "react"
import {
  BrowserRouter,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import "./App.less"
import { Router } from "./Router"
import CommonDialogLayout from "./components/CommonDialogLayout/CommonDialogLayout"
import { uiController } from "./controllers/uiController"
import { commonUtils } from "./helpers/commonUtils"
import { routerService } from "./services/routerService/routerService"
import { userService } from "./services/userService"

import "dayjs/locale/zh-cn"

dayjs.locale("zh-cn")

const RouterLayout = () => {
  // 只要使用了 useNavigate ，url 变化都会导致此组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = useParams()
  routerService.navigate = useNavigate()
  const location = useLocation()
  routerService.location = location
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()
  routerService.setURLSearchParams = setSearchParams
  useLayoutEffect(() => {
    routerService.params = {
      ...params,
      ...commonUtils.getUrlParams(),
    }
  })
  return null
}

export default function App() {
  const [messageApi, contextHolder] = message.useMessage()
  uiController.message = messageApi
  return (
    <ConfigProvider
      locale={locale}
      autoInsertSpaceInButton={false}
      theme={{
        token: {
          colorPrimary: "#3872E0",
          colorText: "#212B36",
        },
      }}
    >
      <BrowserRouter>
        <CommonDialogLayout />
        {contextHolder}
        <RouterLayout />
        <Router />
      </BrowserRouter>
    </ConfigProvider>
  )
}

uiController.init()
userService.init()
