import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace user {
  /** 登录 */
  export function login(data: { username: string, password: string }): Promise<ApiBaseResult & {
    data: {
      accessToken: string
    }
  }> {
    return post('/auth/login', {}, data)
  }

  /** 获取用户信息 */
  export function getUser(): Promise<ApiBaseResult & {
    data: ServerData.UserData
  }> {
    return get('/auth/self')
  }

}

