import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace rule {

  /** 创建规则 */
  export function create(data: PostData.AdRule.Rule): Promise<ApiBaseResult> {
    return post('/landing_page/ab_rule', {}, data)
  }

  /** 更新规则 */
  export function update(ruleId: number, data: PostData.AdRule.Rule): Promise<ApiBaseResult> {
    return post('/landing_page/ab_rule/update', { ruleId }, data)
  }

  /** 删除规则 */
  export function remove(ruleId: number): Promise<ApiBaseResult> {
    return post('/landing_page/ab_rule/delete', { ruleId })
  }

  /** 获取规则详情 */
  export function detail(ruleId: number, visitStartTime?: TimestampSec, visitEndTime?: TimestampSec): Promise<ApiBaseResult & {
    data: ServerData.AdRule.Rule
  }> {
    return get('/landing_page/ab_rule/detail', { ruleId, visitStartTime, visitEndTime })
  }

  /** 获取规则列表 */
  export function list(data: PostData.AdRule.RuleListParams): Promise<ApiBaseResult & {
    data: {
      data: Array<ServerData.AdRule.Rule>
      totalCount: number
    }
  }> {
    return get('/landing_page/ab_rule/list', { ...data })
  }

  /** 转化分析任务 */
  export function conversionAnalyse(ruleId: number): Promise<ApiBaseResult & {
    data: Boolean
  }> {
    return get('/landing_page/ab_rule/conversion_analyse', { ruleId })
  }

  /** 转化分析记录 */
  export function conversionAnalyseRecord(data: PostData.AdRule.ConversionAnalyseRecordsParams): Promise<ApiBaseResult & {
    data: {
      data: Array<ServerData.AdRule.Rule>
      totalCount: number
    }
  }> {
    return get('/landing_page/ab_rule/conversion_analyse_record', { ...data })
  }

}

