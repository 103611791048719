import { observer } from 'mobx-react'
import { FC } from 'react'

interface CommonDialogLayoutProps {}

const CommonDialogLayout: FC<CommonDialogLayoutProps> = observer(
  (props: CommonDialogLayoutProps) => {
    return (
      <div></div>
    )
  }
)

export default CommonDialogLayout
